import React from 'react';
import withRoot from '../../../withRoot';
import BaseGraph from '../base-graph'
import { horizontalBarDatasetOptions, horizontalBarOptions, horizontalBarPlugins } from '../constants'

class EventFormatsChart extends React.Component {
  render() {
    return (
      <BaseGraph
        type='horizontalBar'
        tableTitle="U-LEAD events by format (%)"
        url='/graph/event_formats'
        chartOptions={horizontalBarOptions()}
        datasetOptions={horizontalBarDatasetOptions}
        filter={this.props.filter}
        plugins={horizontalBarPlugins()}
        noActions={this.props.noActions}
      />
    )
  }
}

export default withRoot(EventFormatsChart);
