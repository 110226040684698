import React from 'react';
import BaseGraph from '../base-graph';
import withRoot from '../../../withRoot';
import {barDatasetOptions, barOptions, barPlugins} from "../constants";

class EventTargetGroupsChart extends React.Component {
  render() {
    return (
      <BaseGraph
        type='bar'
        tableTitle="U-LEAD events by target group (%)"
        url='/graph/event_target_groups'
        chartOptions={barOptions()}
        datasetOptions={barDatasetOptions}
        filter={this.props.filter}
        plugins={barPlugins()}
        noActions={this.props.noActions}
      />
    );
  }
}

export default withRoot(EventTargetGroupsChart);
