import React from "react";
import {observer} from "mobx-react";
import {
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import {Link, Redirect} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import withRoot from "../../withRoot";
import {Form, Field} from "react-final-form";
import {StoreContext} from "../../stores/context";
import {required} from "../../validators/required";
import {forgotPasswordRoute} from "../../utils/routes";
import {Breakpoints} from "../../config";
import {config} from "../../style/config";

const styles = (theme) => ({
    root: {
        fontFamily: "Montserrat!important",
        fontStyle: "normal",
    },

    centered:
        {
            '& > *, & *':
                {
                    margin: "0",
                    position: "absolute",
                    left: "0",
                    right: "0",
                    marginLeft: "auto",
                    marginRight: "auto",
                }
        },

    cover: {
        background: "url('/images/Gradient_blue_01_RGB.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",

        width: "100%",
        height: "512px",
        left: "0px",
        top: "0px",
        [theme.breakpoints.down(Breakpoints.md)]: {
            height: "332px",
        },
        "& h1": {
            top: "120px",
            width: "100%",
            height: "117px",

            fontWeight: "900",
            fontSize: "96px",
            lineHeight: "117px",
            /* identical to box height */
            textAlign: "center",
            textTransform: "uppercase",
            color: "#F4D332",

            [theme.breakpoints.down(Breakpoints.md)]: {
                top: "110px",
                fontSize: "34px",
                lineHeight: "41px",
            },
        },
        "& h4": {

            width: "100%",
            height: "98px",
            top: "265px",
            fontWeight: "500",
            fontSize: "40px",
            lineHeight: "49px",
            textAlign: "center",
            color: "#ECBC2D",
            [theme.breakpoints.down(Breakpoints.md)]: {
                top: "161px",
                fontSize: "17px",
                lineHeight: "21px",
            },
        },
        "& p": {

            width: "97px",
            height: "17px",
            top: "453px",

            fontSize: "14px",
            lineHeight: "17px",
            textAlign: "center",
            color: "#F7F9FB",

            [theme.breakpoints.down(Breakpoints.md)]: {
                top: "274px",
            },
        },
    },
    logo: {
        position: "absolute",
        width: "124px",
        height: "40.96px",
        left: "60px",
        top: "40px",
        background: "url('/images/U-LEAD_Logo_yellow_light_Ukr.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
    },
    arrow: {
        position: "absolute",
        width: "12.27px",
        height: "12px",
        top: "480px",
        [theme.breakpoints.down(Breakpoints.md)]: {
            top: "300px",
        },
    },
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "310px",
        margin: "10px auto 0",
        justifyContent: "stretch",
    },
    formContainer: {

        width: "100%",
        left: "0px",
        top: "512px",
        background: "#F7F9FB",
        minHeight: "415px",
        [theme.breakpoints.down(Breakpoints.md)]: {
            top: "332px",
        },

        // [theme.breakpoints.down(1600)]: {
        //     padding: "0 0 15px",
        // },
    },
    checkbox: {
        "& .checkbox": {
            width: "20px",
            height: "20px",
            borderRadius: "5px",
            backgroundColor: "#E1EDFB",
            "&--checked": {
                backgroundImage: "url(/images/icons/icon-check-mark.svg)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
            },
        },
    },
    forgotLink: {
        color: "#778899",
        fontSize: "12px",
        marginBottom: "30px",
    },
    buttonContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& button": {
            backgroundColor: "#4D64A9!important",
            width: "120px!important",
            marginTop: "10px",
            marginBottom: "10px",
            [theme.breakpoints.down(1600)]: {
                marginTop: "10px",
                marginBottom: "10px",
            },
            "& span": {
                fontFamily: "Montserrat!important",
                fontWeight: "500",
                fontStyle: "normal!important",
                fontSize: "14px!important",
            }
        }
    },

    input: {
        width: "310px",
        position: "relative",
        // left: "0",
        // right: "0",
        // marginLeft: "auto",
        // marginRight: "auto",

    },
    checkboxInput:
        {
            "& label":
                {
                    marginTop: "10px!important",
                    width: "310px",
                },

        },
    textInput:
        {
            marginTop: "30px!important",
            marginBottom: "0!important",

            "& > div":
                {
                    margin: "0!important",
                },
            "& > div > label": {
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "24px",
                lineHeight: "29px",
                textAlign: "center!important",
                paddingTop: "0!important",
                color: "#4D64A9!important",
                [theme.breakpoints.down(Breakpoints.md)]: {
                    fontSize: "17px",
                }
            },

            "& input": {
                fontStyle: "normal",
                fontFamily: config.fontFamily,
                fontSize: config.input.fontSize,
                fontWeight: config.input.fontWeight,
                color: config.input.color,
                lineHeight: "15px",
                height: "30px",
                paddingLeft: "26px!important",
                [theme.breakpoints.down(Breakpoints.md)]: {
                    fontSize: config.small.input.fontSize,
                    fontWeight: config.small.input.fontWeight,
                },
            }
        }
});

class Login extends React.Component {
    static contextType = StoreContext;

    onSubmit = async (values) => {
        const {authStore, routerStore} = this.context;

        try {
            await authStore.signin(
                values.username,
                values.password,
                values.remember_me
            );
            routerStore.goHome();
        } catch (ex) {
        }
    };

    render() {
        const {classes} = this.props;
        const {authStore, userStore} = this.context;

        if (userStore.id) {
            return <Redirect to="/"/>;
        }

        return (
            <>
                <div >
                    <div className={classes.cover}>
                        <div className={classes.logo}></div>
                        <div className={classNames(classes.title, classes.centered)}>
                            <h1>Welcome</h1>
                            <h4>
                                to U-DATA,
                                <br/>
                                U-LEAD's data monitoring system
                            </h4>
                            <p>please sign in</p>
                            <div className={classNames(classes.arrow)}>
                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.13639 12C6.28199 11.9999 6.42475 11.9597 6.54898 11.8837C6.67321 11.8078 6.7741 11.6991 6.84055 11.5695L12.1851 1.15311C12.2472 1.03246 12.2772 0.897868 12.2722 0.762284C12.2672 0.626699 12.2275 0.494669 12.1568 0.378877C12.0861 0.263084 11.9868 0.167418 11.8684 0.101068C11.7501 0.0347187 11.6166 -8.35244e-05 11.481 1.50528e-07H0.791791C0.656141 4.97703e-06 0.522767 0.0348564 0.404458 0.101217C0.286148 0.167578 0.186868 0.26322 0.116142 0.378973C0.045416 0.494726 0.00561484 0.626712 0.000551839 0.762268C-0.00451116 0.897823 0.025334 1.03241 0.0872285 1.15311L5.43182 11.5695C5.49836 11.6991 5.59932 11.8078 5.72362 11.8838C5.84791 11.9597 5.99072 11.9999 6.13638 12H6.13639ZM2.08783 1.58358H10.1846L6.13639 9.47365L2.08783 1.58358Z"
                                        fill="#ADD8E6"/>
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className={classNames(classes.formContainer)}>
                        <Form
                            onSubmit={this.onSubmit}
                            render={({handleSubmit, form, submitting, pristine, values}) => (
                                <form
                                    autoComplete="off"
                                    onSubmit={handleSubmit}
                                    className={classes.form}
                                >
                                    {authStore.errorMessage.length > 0 && (
                                        <div className={classes.authException}>
                                            {authStore.errorMessage}
                                        </div>
                                    )}

                                    <div className={classNames(classes.textInput, classes.input)}>
                                        <Field name="username" validate={required}>
                                            {({input, meta}) => (
                                                <TextField
                                                    label="Username"
                                                    margin="normal"
                                                    className={classNames("text-field", {
                                                        "error-field": meta.error && meta.touched,
                                                    })}
                                                    variant="outlined"
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={classNames(classes.textInput, classes.input)}>
                                        <Field name="password" validate={required}>
                                            {({input, meta}) => (
                                                <TextField
                                                    label="Password"
                                                    type="password"
                                                    margin="normal"
                                                    className={classNames("text-field", {
                                                        "error-field": meta.error && meta.touched,
                                                    })}
                                                    variant="outlined"
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={classNames(classes.checkboxInput, classes.input)}>
                                        <Field name="remember_me">
                                            {({input}) => (
                                                <FormControlLabel
                                                    className="remember-link"
                                                    control={
                                                        <Checkbox
                                                            className={classes.checkbox}
                                                            value="checked"
                                                            icon={<div className="checkbox"></div>}
                                                            checkedIcon={
                                                                <div className="checkbox checkbox--checked"></div>
                                                            }
                                                            {...input}
                                                        />
                                                    }
                                                    label="Remember me"
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={classes.buttonContainer}>
                                        <Button
                                            className="btn-login"
                                            variant="contained"
                                            type="submit"
                                            size="large"
                                            color="primary"
                                        >
                                            Sign In
                                        </Button>

                                        <Link className={classes.forgotLink} to={forgotPasswordRoute}>
                                            Forgot your password?
                                        </Link>
                                    </div>
                                </form>
                            )}
                        />

                    </div>

                </div>

            </>
        );
    }
}

export default withRoot(withStyles(styles)(observer(Login)));
