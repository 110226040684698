import React from 'react';
import withRoot from '../../../withRoot';
import BaseGraph from '../base-graph'
import {horizontalBarDatasetOptions, horizontalBarOptions, horizontalBarPlugins} from "../constants";

class ParticipantEmployerTypesChart extends React.Component {
  render() {
    return (
      <BaseGraph
        type='horizontalBar'
        tableTitle="Participants of U-LEAD events by employer type (%)"
        url='/graph/participant_employer_types'
        chartOptions={horizontalBarOptions()}
        datasetOptions={horizontalBarDatasetOptions}
        filter={this.props.filter}
        plugins={horizontalBarPlugins()}
        noActions={this.props.noActions}
      />
    );
  }
}

export default withRoot(ParticipantEmployerTypesChart);
