import React from 'react';
import {
  Grid,
  Button
} from '@material-ui/core';
import fetchData from '../../utils/fetch';
import BaseGraph from '../../components/charts/base-graph';
import S01c from './s01c';

const charts_ph1 = {
  'SO1b': [
    {
      url: '/graph/indicators/SO1b/chart1',
      type: 'bar',
      subTypes: ['bar'],
      title: 'Municipalities’ total revenues according to 5 taxes, mln UAH',
      legend: ['Total AH revenue'],
      isPercent: false,
      footNotes: 'Total AH revenue is calculated as the sum of revenue from personal income, excise, single, property taxes and land fee',
    },
    {
      url: '/graph/indicators/SO1b/chart2',
      type: 'bar',
      subTypes: ['line', 'line'],
      title: 'Own revenues per capita of AHs (UAH)',
      legend: ['Own revenues per capita', 'S01b Milestones'],
      isPercent: false,
      yAxeLabels: ['Value of own revenues per capita, UAH']
      // ? Milestones can be toggled and made visible compared to actual measurement
    },
    {
      url: '/graph/indicators/SO1b/chart3',
      type: 'bar',
      subTypes: ['line'],
      isPercent: true,
      title: 'Percentage change in own revenues per capita in municipality',
      legend: ['Change in own revenues per capita, %']
    }
  ],
  'SO1c': [
    {
      url: '/graph/indicators/SO1c/chart1_1',
      type: 'line',
      subTypes: ['line','line'],
      title: ' Implementation of the Regional Development Strategy 2020 (regarding effective public administration of regional development)',
      legend: [
        'Actual values',
        'Milestone'
      ],
      isPercent: true,
      secondYaxis: false
    },
    {
      url: '/graph/indicators/SO1c/chart1_2',
      type: 'line',
      subTypes: ['line','line'],
      title: ' Implementation of the Regional Development Strategy 2020 (regarding effective public administration of regional development)',
      legend: [
        'Actual values',
        'Milestone'
      ],
      isPercent: true,
      secondYaxis: false
    },
    {
      url: '/graph/indicators/SO1c/chart1_3',
      type: 'line',
      subTypes: ['line','line'],
      title: ' Implementation of the Regional Development Strategy 2020 (regarding effective public administration of regional development)',
      legend: [
        'Actual values',
        'Milestone'
      ],
      isPercent: false,
      secondYaxis: false
    },
  ],
  'SO1d': [
    {
      url: '/graph/indicators/SO1d/chart1',
      type: 'bar',
      subTypes: ['bar'],
      title: 'Percentage of AHs that adopted internal legal procedures',
      legend: ['% of hromadas'],
      isPercent: true,
    },
    {
      url: '/graph/indicators/SO1d/chart2',
      type: 'bar',
      subTypes: ['bar'],
      title: 'Percentage of AHs that adopted internal financial procedures',
      legend: ['% of hromadas'],
      isPercent: true,
    }
  ],
  'SO1e': [
    {
      url: '/graph/indicators/SO1e/chart1',
      type: 'bar',
      subTypes: ['line', 'line'],
      title: 'Awareness of the decentralisation reform in Ukraine',
      legend: ['Actual measurement', 'Milestone'],
      yAxeLabels: [ '% of respondents' ],
      isPercent: true,
    },
    {
      url: '/graph/indicators/SO1e/chart2',
      type: 'horizontalBar',
      subTypes: [],
      title: 'Sources of information about the decentralisation reform',
      legend: ['Percentage of total '],
      yAxeLabels: [ 'Sources of information' ],
      isPercent: true,
    }, {
      url: '/graph/indicators/SO1e/chart3',
      type: 'horizontalBar',
      subTypes: [],
      title: 'Providers of information about the decentralisation reform',
      legend: ['Percentage of total '],
      yAxeLabels: [ 'Providers of information' ],
      isPercent: true,
    },
  ],
  'R1a': [
    {
      url: '/graph/indicators/R1a/chart1',
      type: 'bar',
      subTypes: ['line', 'line'],
      title: [
        'Percentage of training participants able to describe an example of how',
        'the obtained knowledge has contributed to the better fulfilment of tasks'
      ],
      legend: ['Actual measurement', 'Target value'],
      yAxeLabels: [ '% of training participants' ],
      isPercent: true,
      optional: [false, true],
      pointStyle: 'circle'
    },
    {
      url: '/graph/indicators/R1a/chart2',
      type: 'bar',
      subTypes: [ 'bar', 'bar' ],
      title: [
        'Gender distribution of training participants describing a concrete example',
        'of how they have applied their improved capacities in project acquisition'
      ],
      legend: [ 'Male', 'Female' ],
      yAxeLabels: [ '% of participants' ],
      isPercent: true,
      isStacked: true
    },
  ],
  'R2a': [
    {
      url: '/graph/indicators/R2a/chart1',
      type: 'bar',
      subTypes: ['bar'],
      title: 'Percentage of AHs that adopted internal legal procedures',
      legend: ['% of hromadas'],
      isPercent: true,
    },
    {
      url: '/graph/indicators/R2a/chart2',
      type: 'bar',
      subTypes: ['bar'],
      title: 'Supported AHs setting at least 3 local taxes or fees',
      legend: ['% of hromadas'],
      isPercent: true,
    },
  ],
  'R3a': [
    {
      url: '/graph/indicators/R3a/chart1',
      type: 'bar',
      subTypes: ['line', 'line'],
      title: 'Percentage of training participants able to describe an example of how they apply knowledge gained in trainings in their work of process design and moderation',
      legend: ['Actual measurement', 'Target value'],
      isPercent: true,
      yAxeLabels: [ '% of participants' ],
      optional: [false, true]
    }, {
      url: '/graph/indicators/R3a/chart2',
      type: 'bar',
      subTypes: [ 'bar', 'bar' ],
      title: 'Gender distribution of training participants able to describe an example of how the obtained knowledge has contributed to better fulfilment of tasks',
      legend: [ 'Male', 'Female' ],
      yAxeLabels: [ '% of participants' ],
      isPercent: true,
      isStacked: true
    }
  ],
  'R3b': [
    // TODO: 4 lines on the same chart
    {
      url: '/graph/indicators/R3b/chart1',
      type: 'bar',
      subTypes: [ 'line', 'line', 'line', 'line' ],
      title: 'Total number of good practices and initiatives',
      legend: [ 'Number of good practices', 'Number of initiatives', 'Target value for good practices', 'Target value for initiatives' ],
      yAxeLabels: [ '% of participants' ],
      isPercent: false,
      optional: [false, false, true, true]
    },
    {
      url: '/graph/indicators/R3b/chart2',
      type: 'bar',
      subTypes: [ 'bar' ],
      title: 'Distribution of initiatives by type',
      legend: [ 'Number of initiatives' ],
      yAxeLabels: [ 'Value' ],
      isPercent: false,
    },
    {
      url: '/graph/indicators/R3b/chart3',
      type: 'bar',
      subTypes: [ 'bar' ],
      title: 'Distribution of initiatives by target audience',
      legend: [ 'Number of initiatives' ],
      yAxeLabels: [ 'Value' ],
      isPercent: false,
    },
  ],
  'R5a': [
    {
      url: '/graph/indicators/R5a/chart1',
      type: 'bar',
      subTypes: ['line', 'line'],
      title: [
        "Percentage of training participants able to describe a concrete example of how they",
        "have applied their improved capacities in project acquisition in their institution"],
      legend: ['Actual measurement', 'Target value'],
      isPercent: true,
      yAxeLabels: [ '% of participants' ],
      optional: [false, true]
    },
    {
      url: '/graph/indicators/R5a/chart2',
      type: 'horizontalBar',
      subTypes: [ ],
      title: [
        "Percentage of training participants able to describe a concrete example of how they",
        "have applied their improved capacities in project acquisition in their institution"
      ],
      legend: [ '% of Yes', '% of No' ],
      yAxeLabels: [ '% of participants' ],
      isPercent: true,
      isStacked: true
    },
    {
      url: '/graph/indicators/R5a/chart3',
      type: 'bar',
      subTypes: [ 'bar', 'bar' ],
      title: [
        'Gender distribution of training participants able to describe a concrete example of how',
        'they have applied their improved capacities in project acquisition in their institution'
      ],
      legend: [ 'Male', 'Female' ],
      yAxeLabels: [ '% of participants' ],
      isPercent: true,
      isStacked: true
    },
    {
      url: '/graph/indicators/R5a/chart4',
      type: 'horizontalBar',
      subTypes: [],
      title: 'Capacities in project acquisition improved by training participants',
      legend: ['Percentage of training participants'],
      isPercent: true,
      // isStacked: true
    }
  ],
  'R6a': [
    {
      url: '/graph/indicators/R6a/chart1',
      type: 'bar',
      subTypes: ['line', 'line'],
      title: 'Number of dialogue events with civil society supported by U-LEAD',
      legend: ['Number of events', '% satisfaction overall'],
      yAxeLabels: [ 'Number of events', '% satisfaction overall' ],
      isPercent: false,
      isPercent2: true,
      secondYaxis: true
    }
  ],
  'R6b': [
    {
      url: '/graph/indicators/R6b/chart1',
      type: 'bar',
      subTypes: ['bar', 'line'],
      title: 'Milestones for number of participants',
      legend: ['Number of participants', '% satisfaction overall'],
      yAxeLabels: [ 'Number of participants', '% satisfaction overall' ],
      isPercent: false,
      isPercent2: true,
      secondYaxis: true
    },
    {
      url: '/graph/indicators/R6a/chart1',
      type: 'bar',
      subTypes: ['bar', 'line'],
      title: 'Milestones for number of dialogue events',
      legend: ['Number of events', '% satisfaction overall'],
      yAxeLabels: [ 'Number of events', '% satisfaction overall' ],
      isPercent: false,
      isPercent2: true,
      secondYaxis: true
    }
  ],
  'R7a': [
    {
      url: '/graph/indicators/R7a/chart1',
      type: 'bar',
      subTypes: ['line', 'line'],
      title: 'Number of forums for dialogue with the national and regional decision-makers organised or supported by U-LEAD',
      legend: ['Actual measurement', 'Milestones'],
      isPercent: false,
      yAxeLabels: [ 'Number of statements' ],
      optional: [false, true]
    }
  ],
  'R7b': [
    {
      url: '/graph/indicators/R7b/chart1',
      type: 'bar',
      subTypes: ['line', 'line'],
      title: 'Number of statements elaborated by local networks',
      legend: ['Actual measurement', 'Milestones'],
      isPercent: false,
      yAxeLabels: [ 'Number of forums' ],
      optional: [true]
    }
  ],
}

const charts_ph2 = {
  'SO1': [
  {
    url: '/graph/indicators/SO1/chart1',
    type: 'bar',
    subTypes: ['bar'],
    title: ['Number of (existing or new) legislative or normative documents defining tasks / functions / structures of national,' ,'regional or local institutions with regard to decentralisation, and in line with the ECLSG, adopted.'],
    legend: ['Number of documents'],
    //yAxeLabels: [ 'Number of events', '% satisfaction overall' ],
    isPercent: false,
    isPercent2: false,
    secondYaxis: false
  }
],
  'SO2': [
    {
      url: '/graph/indicators/SO2/chart1',
      type: 'bar',
      subTypes: ['bar'],
      title: ['Number of gender-sensitive project proposals for sustainable local development (SLD) of regional significance submitted for financing.', 'By period'],
      legend: ['Number of proposals'],
      //yAxeLabels: [ 'Number of events', '% satisfaction overall' ],
      isPercent: false,
      isPercent2: false,
      secondYaxis: false
    }, {
      url: '/graph/indicators/SO2/chart2',
      type: 'horizontalBar',
      subTypes: [],
      title: 'By oblast total',
      legend: ['Total'],
      yAxeLabels: [ 'By oblast total' ],
      isPercent: false,
    },
  ],
  'SO3': [
    {
      url: '/graph/indicators/SO3/chart1',
      type: 'bar',
      subTypes: ['bar'],
      title: ['Number of municipalities that have adjusted internal procedures in accordance with', 'the European Charter for Local Self-Government in order to ensure an effective administration.', 'By period'],
      legend: ['Number of municipalities'],
      //yAxeLabels: [ 'Number of events', '% satisfaction overall' ],
      isPercent: false,
      isPercent2: false,
      secondYaxis: false
    }
    , {
      url: '/graph/indicators/SO3/chart2',
      type: 'horizontalBar',
      subTypes: [],
      title: 'By oblast total',
      legend: ['Total'],
      yAxeLabels: [ 'By oblast total' ],
      isPercent: false,
    },
  ],
  'SO4': [
    {
      url: '/graph/indicators/SO4/chart1',
      type: 'bar',
      subTypes: ['bar'],
      title: 'Number of new measures resulting from inter-institutional coordination mechanisms are implemented by the relevant actors.',
      legend: ['Number of new measures'],
      //yAxeLabels: [ 'Number of events', '% satisfaction overall' ],
      isPercent: false,
      isPercent2: false,
      secondYaxis: false
    }
  ],
  'R1a': [
    {
      url: '/graph/indicators/R1a/chart1',
      type: 'bar',
      subTypes: ['line', 'bar'],
      title: ['Percentage of national actors who have participated in capacity development measures describe an example',  'of how the acquired knowledge can improve their performance of tasks or harmonise regional policy and sectoral reforms.'],
      legend: ['% national actors', '% women'],
      yAxeLabels: ['% national actors', '% women'],
      isPercent: true,
      isPercent2: true,
      secondYaxis: true
    }
  ],
  'R2a': [
    {
      url: '/graph/indicators/R2a/chart1',
      type: 'bar',
      subTypes: ['bar'],
      title: ['Number of municipalities that have organised one dialogue event with non-governmental actors, including representatives of local businesses,',  'on a topic relevant for municipal decision-making, taking into account gender-relevant aspects.'],
      legend: ['Municipalitiess'],
      //yAxeLabels: [ 'Number of events', '% satisfaction overall' ],
      isPercent: false,
      isPercent2: false,
      secondYaxis: false
    }
  ],
  'R2b': [
    {
      url: '/graph/indicators/R2b/chart1',
      type: 'bar',
      subTypes: ['bar'],
      title: ['Percentage of participants in the dialogue events that state that they are “confident” or “very confident” that', 'the results of the dialogue event will be considered in the municipal decision-making process.'],
      legend: ['Percentage of new participants'],
      //yAxeLabels: [ 'Number of events', '% satisfaction overall' ],
      isPercent: true,
      isPercent2: false,
      secondYaxis: false
    }
  ],
  'R3b': [
    {
      url: '/graph/indicators/R3b/chart1',
      type: 'bar',
      subTypes: ['bar'],
      title: ['Number of municipalities whose representatives can give an example to prove that their municipality has improved the fulfilling of one municipal task', 'based on experiences (good practice examples) which they have gained via national and/or international partnerships or exchanges.'],
      legend: ['Participants'],
      //yAxeLabels: [ 'Number of events', '% satisfaction overall' ],
      isPercent: false,
      isPercent2: false,
      secondYaxis: false
    }
  ],
  'R4a': [
    {
      url: '/graph/indicators/R4a/chart1',
      type: 'bar',
      subTypes: ['bar'],
      title: 'Number of (vertical or horizontal) inter-institutional coordination mechanisms newly designed or adapted.',
      legend: ['Mechanisms'],
      //yAxeLabels: [ 'Number of events', '% satisfaction overall' ],
      isPercent: false,
      isPercent2: false,
      secondYaxis: false
    }
  ],
  'R4b': [
    {
      url: '/graph/indicators/R4b/chart1',
      type: 'bar',
      subTypes: ['bar'],
      title: 'Number of municipalities that have presented their concerns and experiences regarding the implementation of the decentralisation reform in newly designed or adapted exchange formats to the representatives of relevant national and/or regional institutions.',
      legend: ['Municipalities'],
      //yAxeLabels: [ 'Number of events', '% satisfaction overall' ],
      isPercent: false,
      isPercent2: false,
      secondYaxis: false
    }
  ],
}
const SelectGraphs = ({ idSelected ,selectGraphsId}) => {

  const handleChange = (value) => {
    selectGraphsId(value)
  }

  return (
    <div className="radion-btn">
      <div className={idSelected === '0' ? 'activeItem' : 'radion-btn__wrap'} onClick={() => handleChange('0')}>
        SSRD Indicator A:  % of general budget funding allocated to SFRD
      </div>
      <div className={idSelected === '1' ? 'activeItem' : 'radion-btn__wrap'} onClick={() => handleChange('1')}>
        SSRD Indicator B:  % of RD projects that meet SSRD/RD priorities
      </div>
      <div className={idSelected === '2' ? 'activeItem' : 'radion-btn__wrap'} onClick={() => handleChange('2')}>
        SSRD Indicator C: RDAs established
      </div>
    </div>
  )
}


const chartStyleOptsTpl = (type, isPercent, isStacked, isPercent2, secondYaxis) => {

  if (type == 'horizontalBar'){
    return {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: 'label'
      },
      legend: {
        display: false,
        labels: {
          usePointStyle: true
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: true
            },
            ticks: {
              beginAtZero: true,
              callback: function(value, index, values) { return value + (isPercent ? '%' : ''); }
            },
            stacked: isStacked,
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: false
            },
            stacked: isStacked,
            //barThickness: 10
          }
        ]
      }
    }
  }else{
    const opts = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: 'label'
      },
      legend: {
        display: false,
        labels: {
          usePointStyle: true
        }
      },
      elements: {
        line: {
          fill: false
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false
            },
            labels: [],
            stacked: isStacked,
            ticks: {
              autoSkip: false
            }
            //barThickness: 10
          }
        ],
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-1',
            gridLines: {
              display: false
            },
            labels: {
              show: true
            },
            ticks: {
              beginAtZero: true,
              callback: (value => value + (isPercent ? '%' : ''))
            },
            stacked: isStacked,
            //barThickness: 10
          }
        ]
      }
    }
    if (secondYaxis){
      opts.scales.yAxes.push({
        type: 'linear',
        display: true,
        position: 'right',
        id: 'y-axis-2',
        gridLines: {
          display: false
        },
        labels: {
          show: true
        },
        ticks: {
          beginAtZero: true,
          callback: (value => value + (isPercent2 ? '%' : ''))
        },
        stacked: isStacked,
        //barThickness: 10
      })
    }
    return opts;
  }
};

const colors = [
  '#6495ed', '#fdbb2d', '#36b13d', '#865ef9', '#ec595b', '#47e5ec'
]

const simpleDatasetOptsTpl = function(label, type, color, secondYaxis, borderDash = [0]){

  const opts = {
    label: label,
    type: type,
    data: [50, 20, 30, 40],
    borderDash: borderDash,
    fill: false,
    borderColor: color,
    backgroundColor: color,
    pointBorderColor: color,
    pointBackgroundColor: color,
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: color,
    lineTension: 0,
    spanGaps: false,

  }
  if (secondYaxis) { opts.yAxisID = 'y-axis-2' }
  return opts;
}

const pluginsTpl = (type, isPercent, isPercent2, secondYaxis, atSecondAxis) => {
  return [{
    afterDatasetDraw: (chartInstance, easing) => {
      const ctx = chartInstance.chart.ctx;
      ctx.position = 'relative';
      ctx.font = chartInstance.scales.font;
      ctx.fillStyle = '#555';
      ctx.textAlign = "center";
      ctx.textBaseline = "bottom";
      chartInstance.config.options.layout.padding.right = 30;

      const asHorizontal = type == 'horizontalBar';
      chartInstance.data.datasets.forEach(function (dataset, i) {
        var meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach(function (bar, index) {
          const setting = chartInstance.data.datasets[i]._meta;

            var data = dataset.data[index];
            let text = '' + Math.round(data)
            if ((i == 0 || !secondYaxis) && isPercent ||
                (atSecondAxis ? atSecondAxis[i] : i == 1) && secondYaxis && isPercent2){
              text += '%';
            }
          if (chartInstance.data.datasets ) {
            if (asHorizontal) {
              ctx.fillText(text, bar._model.x + 15, bar._model.y + 5);
            } else {
              Object.values(setting)[0].hidden === null && ctx.fillText(text, bar._model.x, bar._model.y - 7);
            }
          }
        });
      })

    }
  }];
}

class IndicatorGraphs extends React.Component {

  state = {
    indicators: [],
    selectedIndicatorId: null,
    selectedIndicatorCode: '',
    idGraphs: '0'
  }

  componentWillMount(){
    this.getIndicators()
  }

  async getIndicators() {

    // getting indicators from api,api should consider users active phase
    const data = await fetchData('get', '/indicators');
    let indicatorId = window.location.href.match(/indicator-graphs\/?(\d+)/)
    if (indicatorId){ indicatorId = indicatorId[1]; }
    this.setState({ indicators: data[0]}, ()=> {
      if (!indicatorId) { return; }
      this.handleIndicatorSelect(indicatorId)()
    });
  }

  selectGraphsId = (id) => {
    this.setState({
      idGraphs: id
    });
  }

  handleIndicatorSelect = (indicatorId) => {
    return async () => {
      // TODO: request indicator graphs
      const indicator = this.state.indicators.find(x => x.id == indicatorId )
      this.setState({selectedIndicatorId: indicatorId, selectedIndicatorCode: indicator.code})
    }
  }

  render() {
    const { classes, lang, user } = this.props;
    const isFirstPhase = user && user.currentPhaseNumber == 1;

    const charts = isFirstPhase ? charts_ph1 : charts_ph2;

    const {
      indicators,
      selectedIndicatorId,
      selectedIndicatorCode
    } = this.state;

    return (
      <Grid item container xs={12}>
        <Grid item xs={2}>
          {indicators.map(indicator => {
            if (indicator.fake || !charts[indicator.code]) { return '' }
            return (
              <Button
                variant={indicator.id == selectedIndicatorId ? 'contained': 'text'}
                fullWidth="true"
                color="default"
                onClick={this.handleIndicatorSelect(indicator.id)}
                key={indicator.id}
              >
                {indicator.code}
              </Button>
            )
          })}
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={9}>
          {selectedIndicatorCode === 'SO1c' &&
            <SelectGraphs idSelected={ this.state.idGraphs } selectGraphsId={(id) => this.selectGraphsId(id)}/>
          }
          {selectedIndicatorCode === 'SO1c' ?
            <S01c
              chart={charts[selectedIndicatorCode][this.state.idGraphs]}
              simpleDatasetOptsTpl={(
                label,
                subTypes,
                colors,
                secondYaxis
              ) => simpleDatasetOptsTpl(
                label,
                subTypes,
                colors,
                secondYaxis
              )}
              colors={colors}
              chartStyleOptsTpl={(
                type,
                isPercent,
                isStacked,
                isPercent2,
                secondYaxis
              ) => chartStyleOptsTpl(
                type,
                isPercent,
                isStacked,
                isPercent2,
                secondYaxis
              )}
              pluginsTpl={(
                type,
                isPercent,
                isPercent2,
                secondYaxis,
                atSecondAxis
              ) => pluginsTpl(
                type,
                isPercent,
                isPercent2,
                secondYaxis,
                atSecondAxis
              )}
              selectGraphsId={(id) => this.selectGraphsId(id)}
              idGraphs={this.state.idGraphs}
            /> :
            selectedIndicatorCode && (charts[selectedIndicatorCode] || []).map((chart) => {
              const datasetOptions = chart.legend.map((label, i) =>
                simpleDatasetOptsTpl(
                  label,
                  chart.subTypes[i],
                  colors[i],
                  chart.secondYaxis && (chart.atSecondAxis ? chart.atSecondAxis[i] : i == 1),
                   ((i % 2 !== 0) && (chart.title === 'Own revenues per capita of AHs (UAH)')) ? [10, 5] : [0]
                )
              )
              const options = chartStyleOptsTpl(chart.type, chart.isPercent, chart.isStacked, chart.isPercent2, chart.secondYaxis)
              if (chart.footNotes) {
                options.scales.xAxes[0].scaleLabel = {
                  display: true,
                  labelString: chart.footNotes,
                  fontColor: '#aaa',
                  fontSize: 12
                }
              }
              if (datasetOptions.length > 1 || chart.subTypes.length > 1) {
                options.legend.display = true
              }
              const plugins = pluginsTpl(chart.type, chart.isPercent, chart.isPercent2, chart.secondYaxis, chart.atSecondAxis)

              return (
                <BaseGraph
                  type={chart.type}
                  tableTitle={chart.title}
                  url={chart.url}
                  chartOptions={options}
                  datasetOptions={datasetOptions}
                  plugins={plugins}
                />
              )
            })
          }

        </Grid>
      </Grid>
    )
  }
}

export default IndicatorGraphs;
