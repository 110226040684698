import React from 'react';
import BaseGraph from '../base-graph';
import withRoot from '../../../withRoot';
import {barDatasetOptions, barOptions, barPlugins, horizontalBarOptions} from "../constants";
class EventByTSPChart extends React.Component {
  render() {
    return (
    <BaseGraph
        type='bar'
        tableTitle='U-LEAD events by TSP (number)'
        url='/graph/event_tsp'
        chartOptions={barOptions('number')}
        datasetOptions={barDatasetOptions}
        filter={this.props.filter}
        plugins={barPlugins('number')}
        noActions={this.props.noActions}
    />
    );
  }
}

export default withRoot(EventByTSPChart);
