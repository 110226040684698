import React from 'react';
import BaseGraph from '../base-graph';
import withRoot from '../../../withRoot';
import {barDatasetOptions, barOptions, barPlugins, horizontalBarOptions} from "../constants";
class EventByYearChart extends React.Component {
  render() {
    return (
    <BaseGraph
        type='bar'
        tableTitle='U-LEAD events by year'
        url='/graph/event_year'
        chartOptions={barOptions('number')}
        datasetOptions={barDatasetOptions}
        plugins={barPlugins('number')}
    />
    );
  }
}

export default withRoot(EventByYearChart);
