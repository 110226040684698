import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { coloredLayers, layers, tabs } from './../constant';
import { default as layersMeta } from './../layers.json';
import { styles } from './style';
import { ColorMarker } from './../color-marker';
import RenderListItem from '../../../components/dropdown/list-item';
import { clone, difference } from 'lodash';
import {
  Drawer,
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Link,
  Button,
  Switch
} from '@material-ui/core';


class MiniDrawer extends React.Component {
  state = {
    openDrawer: false,
    openSelect: [],
    checked: [],
  };

  handleDefault = () => {
    this.props.handleDefault();
    this.setState({
      openDrawer: false,
      openSelect: [],
      checked: [],
    });
  }

  handleToggle = (value) => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const onFilterLayerClick = this.props.onFilterLayerClick;

    const hasPlace = this.state.checked.length+1 <= 3;
    if (typeof onFilterLayerClick === 'function') {
      onFilterLayerClick(value)();
    }

    if (currentIndex === -1) {
      newChecked.push(value);
      if (!hasPlace) { newChecked.shift() }
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({
      checked: newChecked,
    });
  };

  handleDrawer = () => {
    const newOpenState = !this.state.openDrawer;
    this.setState({
      openDrawer: newOpenState,
      openSelect: []
      });

    const element = document.querySelector('.leaflet-control-easyPrint');

    if (element){
      if (newOpenState) {
        element.classList.add("pl-275");
      } else {
        element.classList = "leaflet-control-easyPrint leaflet-bar leaflet-control";
      }
    }

    this.props.isOpenDrawer(newOpenState)
  };

  handleDropDown = (id) => {
    let array = this.state.openSelect.concat();
    array[id] = !array[id];
    this.setState({
      openSelect: array,
      openDrawer: true
    });

    this.props.isOpenDrawer(true)
  };

  handleColorMarkerChange = (type, value) => {
    const obj = Object.assign({}, this.props.buttonsLabelsColors, { [type]: value });
    this.props.onButtonsLabelsColorsChange(obj);
    this.props.onHandleGradientColorChange(value);
  }

  checkSelect = ( value ) => {
    const { selectedLayers } = this.props;

    if (value) {
      for (let i = 0; i < value.length; i++) {
        if (selectedLayers.indexOf(value[i]) >= 0) {
          return true
        }
      }
    }
    return false
  }

  renderTabs(tab, i, classes) {
    const [tabId, tabHeader] = tab;
    let tabLayers = clone(layers[tabId]);
    if (this.props.isHromadaView && tabLayers){
      tabLayers = difference(tabLayers, ['employer_levels', 'r6b'])
    }
    return (
      <React.Fragment key={i}>
        <ListItem button
          onClick={() =>{
            this.handleDropDown(i + 1, tabLayers)
          }}
          className={classNames("button mh50", { 'select': this.state.openSelect[i + 1] })}
        >
          <ListItemIcon
            className={classNames({ 'selectIcon': this.checkSelect(tabLayers) })}
          >
            <img src={`/images/icons/tabs${i + 1}.svg`} alt="img" className="tabs-img tabsSvg" width="48" />
          </ListItemIcon>
          <ListItemText inset
            primary={tabHeader}
            className={classNames('p0', classes.primaryCustom)}
          />
          {this.state.openSelect[i + 1] ?
            <img src='./images/icons/triangle-bot.png' alt="open" />
            : null
          }
        </ListItem>
        <Collapse in={this.state.openSelect[i+1]} timeout="auto" unmountOnExit className="my-35 select">
          <List component="div" disablePadding>
            {tabLayers && tabLayers.map((layerId, i) => {
              return(
                this.dropDownList(layerId, i)
              )
            })}
            {this.renderGradient(tabLayers)}
            {this.state.checked.length === 3 &&
              <ListItem className={classNames("button mh50 max-filter m0")}>
                Maximum Filters: 3
              </ListItem>
            }
          </List>
        </Collapse>
      </React.Fragment>
    )
  }
  renderGradient = (label) => {
    return (this.props.singleLayerMode || this.props.specialTspMode) && this.gradientCheck();
  }

  gradientCheck() {
    const { classes } = this.props;
    return(
      <div className={classes.gradientWrapp}>
        <label className={classNames('gender', classes.primaryCustom)}>
          <Switch
            checked={this.props.asColorGradient}
            onChange={this.props.toggleColorGradient}
            value="true"
            className={classNames('switch', { 'checked' : this.props.asColorGradient})}
          />
          Gradient colour map
        </label>

        {this.props.isParticipantLayer && (
          <label className={classNames('gender', classes.primaryCustom)}>
            <Switch
              checked={this.props.withGenderProportion}
              onChange={this.props.toggleGenderProportion}
              value="true"
              className={classNames('switch', { 'checked': this.props.withGenderProportion })}
            />
            Gender proportion
          </label>
        )}
      </div>
    );
  }

  renderContribute = (label) => {
    const find = ['event_results', 'participant_results'].indexOf(label) ;
    const select = this.state.checked.indexOf(label)
    const { classes } = this.props;

    if (find >= 0 && select >=0) {
      return(
        <span className={classes.contribute}>
          Events can contribute to multiple U-LEAD with Europe results!
        </span>
      )
    }
    return null;
  }

  renderSubFilterList = (layerId, selectedSections) => {
    const {classes} = this.props;
    const selectedSubIds = (selectedSections || []).map(x => x.id)

    return(
      ((this.state.checked.indexOf(layerId) !== -1)
        && Object.keys(this.props.layersFilterObjects).length > 0
        && layersMeta[layerId].type == 'distribution')
        && this.props.layersFilterObjects[layerId]
        && this.props.layersFilterObjects[layerId].length
        && (
        <div className={classNames(classes.subfilter, 'select')}>
          <br />

          <Link to='#' onClick={this.props.onFilterSubLayerClick(layerId, null)}>
            <Button size='small'
              variant={!selectedSubIds.length ? 'outlined' : 'contained'}
              color="primary"
              className={!selectedSubIds.length ? classes.outlinedCustom : classes.containedCustom}
              style={{ justifyContent: 'center' }}
            >
              All filters
            </Button>
          </Link>
          {this.props.layersFilterObjects[layerId].map((item, i) => {
            const isSelected = selectedSubIds.indexOf(item.id) >= 0;
            return (
              <Link to='#' onClick={this.props.onFilterSubLayerClick(layerId, item)} key={i} className="mr10 fs08">
                <Button
                  size='small'
                  color="primary"
                  variant={isSelected ? 'outlined' : 'contained'}
                  className={isSelected ? classes.outlinedCustom : classes.containedCustom}
                  style={{ justifyContent: 'flex-start' }}
                >
                  {(item.en || item.nameEn || '').split('(').map(
                    (string, index) => index === 1 ? <><br />({string}</> : string
                  )}
                </Button>
              </Link>
            )
          })}
        </div>
      )
    );
  }

  dropDownList = (layerId, i) => {
    const label = layersMeta[layerId].label;
    const { selectedSubLayers, selectedLayers } = this.props;
    const isSelected = selectedLayers.indexOf(layerId) >= 0
    const selectedSections = isSelected ? selectedSubLayers[layerId] : null
    const { classes } = this.props;

    return (
      <React.Fragment key={i}>
        <div>
          <ListItem button
            className={classes.nested, classes.checkbox}
          >
            <Checkbox
              checked={this.state.checked.indexOf(layerId) !== -1}
              tabIndex={-1}
              disableRipple
              className={classNames("checked", classes.checkbox)}
              onClick={
                () => {
                  this.handleToggle(layerId);
                }
              }
              icon={<div className="checkbox"></div>}
              checkedIcon={<div className="checkbox checkbox--checked"></div>}
            />

            <ListItemText inset
              primary={label}
              className={classNames('p0', classes.primaryCustom)}
            />
            {coloredLayers.find(element => element === layerId) &&
              <ColorMarker
                type={layerId}
                value={this.props.buttonsLabelsColors[layerId]}
                onChange={this.handleColorMarkerChange}
              />
            }
          </ListItem>
          {this.renderContribute(layerId)}
        </div>

        {this.renderSubFilterList(layerId, selectedSections)}
      </React.Fragment>
    )
  }

  render() {
    const {
      classes,
      user
    } = this.props
    let currentTabs = clone(tabs)
    if (user && (user.currentPhaseNumber == 2)){
      currentTabs = currentTabs.filter(x => x[0] != 'tab2');
    }

    return (

      <div className={classNames(classes.root , classes.customDrawer) }>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.openDrawer,
            [classes.drawerClose]: !this.state.openDrawer,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.openDrawer,
              [classes.drawerClose]: !this.state.openDrawer,
            }),
          }}
          open={this.state.openDrawer}
        >
          <div className={classNames('toolbar', classes.toolbar)}>
            <IconButton onClick={this.handleDrawer}>
              {!this.state.openDrawer ? <img src='./images/icons/arrow-right.png' alt="right" />
                : <img src='./images/icons/arrow-left.png' alt="left" /> }
            </IconButton>
          </div>

          <List className="wrap-list">
            <RenderListItem
              ListItemTextClass={classes.primaryCustom}
              onClick={this.handleDefault}
              img={'/images/icons/tabs.png'}
              primary={"Default View"}
              isVisible={this.state.openSelect[0]}
            />


            {currentTabs.map((tab, i) => this.renderTabs(
              tab,
              i,
              classes
            ))}

          </List>
        </Drawer>
      </div>
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MiniDrawer);
