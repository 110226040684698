import React from 'react';
import withRoot from '../../../withRoot';
import BaseGraph from '../base-graph'
import { horizontalBarDatasetOptions, horizontalBarOptions, horizontalBarPlugins } from '../constants'

class EventResultsChart extends React.Component {
  render() {
    return (
        <BaseGraph
            type='horizontalBar'
            tableTitle="Events organised by U-LEAD distributed by result (%)"
            url='/graph/event_results'
            chartOptions={horizontalBarOptions()}
            datasetOptions={horizontalBarDatasetOptions}
            filter={this.props.filter}
            plugins={horizontalBarPlugins()}
            noActions={this.props.noActions}
        />
    )
  }
}

export default withRoot(EventResultsChart);
